import { CloseIcon, DownloadIcon, EyeIcon } from 'assets'
import { DocumentViewer, Modal } from 'components'

import PropTypes from 'prop-types'
import file from 'utilities/file'
import { useDisclosure } from 'hooks'

const DocumentPreview = ({ name, link, className, fileExtension }) => {
  const { isOpen: isDocumentOpen, open: openDocument, close: closeDocument } = useDisclosure()

  const viewFile = (ev) => {
    ev.stopPropagation()
    openDocument()
  }

  const typeOfDocument = file.getFileType(fileExtension ?? file.getFileExtension(link))

  return (
    <div className={`${className}`}>
      <button onClick={viewFile} className='h-4 w-4' type='button' data-testid='view-document'>
        <EyeIcon className='h-4 w-4 text-grey/400' />
      </button>

      <Modal isOpen={isDocumentOpen} close={closeDocument} dialogClassName='!mt-20 !pt-14'>
        <button type='button' className='modal-close-btn !right-8 !top-8' onClick={closeDocument} data-testid='close-document'>
          <CloseIcon className='modal-close-icon' />
        </button>

        <div data-testid='document-viewer-modal'>
          <div className='flex items-center justify-between'>
            <h3 className='modal-heading'>{name}</h3>
            <a
              href={link}
              target='_blank'
              rel='noreferrer'
              className='  border border-primary/500 text-sm p-2 rounded text-primary/500 flex items-center gap-x-2'
              download={true}
            >
              <DownloadIcon />
              Download
            </a>
          </div>

          <div>{typeOfDocument === 'image' ? <img src={link} alt={name} className='w-full' /> : <DocumentViewer link={link} />}</div>
        </div>
      </Modal>
    </div>
  )
}

export default DocumentPreview

DocumentPreview.propTypes = {
  name: PropTypes.string,
  link: PropTypes.string,
  className: PropTypes.string,
  fileExtension: PropTypes.string,
}
