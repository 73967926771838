import authReducer from './slices/auth.slice'
import { baseService } from './../services/base.service'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import uiReducer from './slices/ui.slice'

export const store = configureStore({
  reducer: {
    authSlice: authReducer,
    uiSlice: uiReducer,
    [baseService.reducerPath]: baseService.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseService.middleware),
})

setupListeners(store.dispatch)
