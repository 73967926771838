import React from 'react'

const SkeletonLoader = () => {
  return (
    <div className='max-w-sm animate-pulse'>
      <div className='h-2.5 bg-gray-200 rounded-full w-48 mb-4'></div>

      <div className='h-2 bg-gray-200 rounded-full max-w-[360px]'></div>
      <span className='sr-only'>Loading...</span>
    </div>
  )
}

export default SkeletonLoader
