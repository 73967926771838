import '@mdxeditor/editor/style.css'

import {
  BoldItalicUnderlineToggles,
  ListsToggle,
  MDXEditor,
  UndoRedo,
  headingsPlugin,
  listsPlugin,
  markdownShortcutPlugin,
  quotePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
} from '@mdxeditor/editor'

import PropTypes from 'prop-types'

const MarkdownEditor = ({ handleChange, placeholder, initialMarkdown }) => {
  return (
    <MDXEditor
      markdown={initialMarkdown}
      onChange={handleChange}
      contentEditableClassName='markdown-editor'
      placeholder={placeholder}
      plugins={[
        headingsPlugin(),
        listsPlugin(),
        quotePlugin(),
        thematicBreakPlugin(),
        markdownShortcutPlugin(),
        toolbarPlugin({
          toolbarContents: () => (
            <>
              {' '}
              <UndoRedo />
              <BoldItalicUnderlineToggles />
              <ListsToggle />
            </>
          ),
        }),
      ]}
    />
  )
}

export default MarkdownEditor

MarkdownEditor.propTypes = {
  handleChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  initialMarkdown: PropTypes.string,
}
