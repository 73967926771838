import PropTypes from 'prop-types'

const TextComponent = ({ mainText, subText, bodyText }) => {
  return (
    <div className='flex flex-col items-center justify-center mt-10'>
      <p className='text-2xl font-medium text-center mt-20 md:text-3xl'>{mainText}</p>
      <p className='text-sm  font-normal leading-[200%] text-center mt-20 pb-0 md:text-base max-w-[62rem] md:leading-[200%]'>{subText}</p>
      <ul>
        <li dangerouslySetInnerHTML={{ __html: bodyText }} className='text-base font-normal leading-9 text-left mt-5 pb-20 md:text-lg' />
      </ul>
    </div>
  )
}

TextComponent.propTypes = {
  mainText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  bodyText: PropTypes.object.isRequired,
}

export default TextComponent
