import { DeleteOutlineIcon2, ImagePlaceholderIcon } from 'assets'

import DocumentPreview from './document-preview'
import PropTypes from 'prop-types'
import file from 'utilities/file'

const DisplayedUploadedFile = ({ uploadedFile, deleteUploadedFile }) => {
  const clearUploadedFile = (ev) => {
    ev.stopPropagation()
    deleteUploadedFile()
  }

  const uploadedFileUrl = file.toObjectUrl(uploadedFile)

  return (
    <div className='border border-grey/100 px-3 py-2 w-full mt-5 rounded-[4px] justify-between flex items-center'>
      <div className='flex'>
        <ImagePlaceholderIcon className='mr-3' />
        <div>
          <p className='mb-1 font-normal text-xs leading-5 text-shades/black'>{uploadedFile?.name || 'File'}</p>
          <p className='font-normal text-[10px] text-grey/300'>{file.formatSize(Number(uploadedFile?.size))}</p>
        </div>
      </div>

      <div className='flex items-center gap-x-3'>
        <DocumentPreview
          name={uploadedFile?.name ?? ''}
          link={uploadedFileUrl}
          className='w-4 h-4'
          fileExtension={file.getFileExtension(uploadedFile?.name ?? '')}
        />

        <button onClick={clearUploadedFile} type='button' data-testid='delete-button'>
          <DeleteOutlineIcon2 className='h-4 w-4 text-error/500' />
        </button>
      </div>
    </div>
  )
}

export default DisplayedUploadedFile

DisplayedUploadedFile.propTypes = {
  uploadedFile: PropTypes.object,
  deleteUploadedFile: PropTypes.func,
}
