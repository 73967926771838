/**
 * converts a file to an object url string.
 * @param file
 * @returns
 */
const toObjectUrl = (file) => {
  return window.URL.createObjectURL(file)
}

export default toObjectUrl
