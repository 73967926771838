import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'constants/auth.constants'

import { cookies } from 'utilities'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  accessToken: cookies.get(ACCESS_TOKEN_KEY),
  refreshToken: cookies.get(REFRESH_TOKEN_KEY),
}

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    setAccessToken: (state, action) => {
      // Set the access token in the cookies for 24 hours.
      cookies.add(ACCESS_TOKEN_KEY, action.payload, 86_400)
      state.accessToken = action.payload
    },
    setRefreshToken: (state, action) => {
      // Set the refresh token in the cookies for 7 days.
      cookies.add(REFRESH_TOKEN_KEY, action.payload, 604_800)
      state.refreshToken = action.payload
    },
    clearTokens: (state) => {
      cookies.remove(ACCESS_TOKEN_KEY)
      cookies.remove(REFRESH_TOKEN_KEY)
      state.accessToken = null
      state.refreshToken = null
    },
    setTokens: (state, action) => {
      cookies.add(ACCESS_TOKEN_KEY, action.payload.accessToken, 86_400)
      cookies.add(REFRESH_TOKEN_KEY, action.payload.refreshToken, 604_800)
      state.accessToken = action.payload.accessToken
      state.refreshToken = action.payload.refreshToken
    },
  },
})

export default authSlice.reducer

export const { setAccessToken, setRefreshToken, clearTokens, setTokens } = authSlice.actions

export const selectAccessToken = (state) => state.authSlice.accessToken
export const selectRefreshToken = (state) => state.authSlice.refreshToken
