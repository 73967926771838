import { Link } from 'react-router-dom'
import { LogoIcon } from 'assets'
import PropTypes from 'prop-types'
import { routes } from 'routes'

const Logo = ({ containerBg, className, withoutText, direction = 'x' }) => {
  return (
    <Link to={routes.home}>
      <div className={`flex ${direction === 'x' ? 'flex-row' : 'flex-col'} items-center gap-x-2 gap-y-1.5 cursor-pointer ${className}`}>
        <LogoIcon className={`${containerBg === 'light' ? 'text-theme' : 'text-white'} h-12 w-24`} />

        {!withoutText && (
          <p
            className={`${direction === 'x' ? 'text-base hidden xs:block' : 'text-sm'} font-medium ${containerBg === 'light' ? 'text-theme' : 'text-white'}`}
          >
            The Hill Emissaries
          </p>
        )}
      </div>
    </Link>
  )
}

export default Logo

Logo.propTypes = {
  containerBg: PropTypes.string,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['x', 'y']),
  withoutText: PropTypes.bool,
}
