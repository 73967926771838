import DisplayedUploadedFile from './displayed-uploaded-file'
import PropTypes from 'prop-types'
import { UploadIcon } from 'assets'
import { useFileUpload } from 'hooks'

const FileUploadField = ({ handleFileUpload, reset, handleFilesUpload, defaultFile, acceptableFiles, invalidFileMessage, multiple }) => {
  const {
    selectFile,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleFileDrop,
    handleFilesSelected,
    // uploadSuccessful,
    uploadedFile,
    inputRef,
    uploadedFiles,
    resetUploadState,
    deleteFileAtIndex,
  } = useFileUpload({
    acceptableFiles,
    invalidFileMessage,
    handleFileUpload,
    defaultFile,
    handleFilesUpload,
    multiple,
  })

  const resetUpload = () => {
    resetUploadState()

    if (reset) {
      reset()
    }
  }

  return (
    <div
      className={`w-full flex flex-col ${uploadedFile ? 'items-start' : 'items-end'}`}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleFileDrop}
    >
      <input
        type='file'
        className='hidden'
        ref={inputRef}
        accept={acceptableFiles?.join(', ')}
        onChange={handleFilesSelected}
        multiple={multiple}
        data-testid='file-input'
      />
      <button
        className='border border-dashed border-secondary/blue/500 rounded-[4px] bg-secondary/blue/50 px-4 py-3 flex items-center justify-between w-full cursor-pointer relative tooltip__file-upload-field'
        onClick={selectFile}
        type='button'
      >
        <div className='flex items-center gap-x-2'>
          <UploadIcon />

          <div>
            <p className='text-xs font-semibold text-[#38393A] leading-5' data-testid='drop-file-text'>
              Drag and drop your file{multiple ? '(s)' : ''} here
            </p>

            <p className='mt-1 text-left text-[10px]'>PNG or JPG</p>
          </div>
        </div>

        <span className='text-secondary/blue/500 font-semibold text-xs'> Browse</span>
      </button>

      {!multiple && uploadedFile ? <DisplayedUploadedFile uploadedFile={uploadedFile} deleteUploadedFile={resetUpload} /> : null}

      {multiple && uploadedFiles?.length > 0 ? (
        <div className='w-full'>
          {uploadedFiles?.map((file, index) => (
            <DisplayedUploadedFile
              uploadedFile={file}
              deleteUploadedFile={() => {
                deleteFileAtIndex(index)
              }}
              key={file?.name}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default FileUploadField

FileUploadField.propTypes = {
  handleFileUpload: PropTypes.func,
  reset: PropTypes.func,
  handleFilesUpload: PropTypes.func,
  defaultFile: PropTypes.object,
  acceptableFiles: PropTypes.array,
  invalidFileMessage: PropTypes.string,
  multiple: PropTypes.bool,
}
