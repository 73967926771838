/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
import { useDisclosure, useListenForOutsideClicks } from 'hooks'

import { MenuIcon } from 'assets'
import PropTypes from 'prop-types'
import SidebarLayout from 'layouts/sidebar-layout'
import { useState } from 'react'

const Hamburger = ({ containerBg }) => {
  const { isOpen: isSidebarOpenForMobile, open: openSidebarForMobile, close: closeSidebarForMobile } = useDisclosure()

  const { elementRef: sidebarLayoutRef } = useListenForOutsideClicks(() => {
    closeSidebarForMobile()
    console.log('auauaua')
  })

  console.log({ isSidebarOpenForMobile })

  const [sidebarToggle, setSidebarToggle] = useState(false)

  const toggleSideBar = () => {
    sidebarToggle ? setSidebarToggle(false) : setSidebarToggle(true)
  }

  const [isNavShorter, setIsNavShorter] = useState(false)

  console.log({ isNavShorter })

  const openRightClassName = isNavShorter ? 'right-[5.75rem]' : 'right-[16rem]'

  return (
    <div className='relative'>
      <div className={`block ${isSidebarOpenForMobile ? openRightClassName : '-right-[0rem]'} z-[100] fixed top-0`} ref={sidebarLayoutRef}>
        <SidebarLayout toggle={sidebarToggle} setToggle={toggleSideBar} isRight setIsNavShorter={setIsNavShorter} close={closeSidebarForMobile} />
      </div>

      <button className='focus:outline-none cursor-pointer relative top-0' onClick={openSidebarForMobile}>
        <MenuIcon className={` w-6 h-6 rounded-full md:hidden ${containerBg === 'light' ? 'text-[#333]' : 'text-white'}`} />
      </button>
    </div>
  )
}

Hamburger.propTypes = {
  containerBg: PropTypes.string.isRequired,
}

export default Hamburger
