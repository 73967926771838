import { alert, validate } from 'utilities'
import { useCreateProgramMutation, useUpdateProgramMutation } from 'services/program.service'
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'

import { ACCEPTABLE_LETTER_FILES } from './upload-media-modal'
import Button from './button'
import FileUploadField from './file-upload-field'
import Input from './input'
import MarkdownEditor from './markdown-editor'
import Modal from './modal'
import ModalCloseBtn from './modal-close-btn'
import PropTypes from 'prop-types'
import StatusMessage from './status-message'
import { useHandleApiResponse } from 'hooks'

const AddProgramModal = ({ isOpen, close, program, action = 'create' }) => {
  const isUpdate = action === 'update'
  const [image, setImage] = useState(null)
  const [linkToApply, setLinkToApply] = useState('')
  const [description, setDescription] = useState('')
  const [title, setTitle] = useState('')
  const [errors, setErrors] = useState({})

  // update state with program details if action is update
  useEffect(() => {
    if (isUpdate && program) {
      setLinkToApply(program.apply_link)
      setDescription(program.description)
      setTitle(program.title)
    }
  }, [program, action])

  const [createProgram, { isLoading: isCreatingProgram, ...response }] = useCreateProgramMutation()

  const [updateProgram, { isLoading: isUpdatingProgram, ...updateResponse }] = useUpdateProgramMutation()

  const clear = () => {
    setImage(null)
    setLinkToApply('')
    setDescription('')
    setTitle('')
    setErrors({})
  }

  const handleCreateOrUpdateSuccess = () => {
    alert.success(isUpdate ? 'Successfully edited program' : 'Successfully created program')
    clear()
    close()
  }

  useHandleApiResponse({
    ...(isUpdate ? updateResponse : response),
    handleSuccess: handleCreateOrUpdateSuccess,
  })

  const handleSetImage = (file) => {
    setErrors((prev) => ({ ...prev, image: '' }))
    setImage(file)
  }

  const handleTitleChange = (value) => {
    setTitle(value)
    setErrors((prev) => ({ ...prev, title: '' }))
  }

  const handleLinkChange = (value) => {
    setLinkToApply(value)
    setErrors((prev) => ({ ...prev, linkToApply: '' }))
  }

  const handleDescriptionChange = (value) => {
    setDescription(value)
    setErrors((prev) => ({ ...prev, description: '' }))
  }

  console.log({ isUpdate })

  const handleSubmit = (ev) => {
    ev.preventDefault()
    const { errors, hasErrors } = validate.createProgram({ image, linkToApply, description, title, isUpdate })

    if (hasErrors) {
      setErrors(errors)
    } else {
      const formData = new FormData()
      if (image) {
        formData.append('image', image)
      }
      formData.append('title', title)
      formData.append('apply_link', linkToApply)
      formData.append('description', description)

      console.log({ isKala: isUpdate })

      isUpdate ? updateProgram({ id: program?.id, body: formData }) : createProgram(formData)
    }
  }

  return (
    <Modal isOpen={isOpen} close={close} dialogClassName={'!w-[1065px] max-w-full !h-[744px] max-h-full'}>
      <ModalCloseBtn close={close} />

      <div className='mt-8 h-[calc(100%_-_112px)] '>
        <h3 className='text-base font-semibold leading-[28px] mb-6 text-[#222]'>Add new program</h3>

        <form className='w-full max-w-full h-[calc(100%_-_52px)] overflow-y-auto pb-16 pr-2' onSubmit={handleSubmit}>
          <div className='flex flex-col gap-y-6 mb-16'>
            <label className='label'>
              <span className='shrink-0 inline-block w-[88px] text-sm font-semibold'>Title</span>

              <div className='w-full'>
                <Input placeholder='Enter title' onChange={handleTitleChange} value={title} />
                <StatusMessage status='error' message={errors?.title ?? ''} />
              </div>
            </label>

            <label className='label'>
              <span className='inline-block w-[88px] text-sm font-semibold shrink-0'>Link to apply</span>

              <div className='w-full'>
                <Input placeholder='Enter link to apply' value={linkToApply} onChange={handleLinkChange} />
                <StatusMessage status='error' message={errors?.linkToApply ?? ''} />
              </div>
            </label>

            <label className='label'>
              <span className='shrink-0 inline-block w-[88px] text-sm font-semibold'>Cover Image</span>

              <div className='w-full'>
                <FileUploadField
                  acceptableFiles={ACCEPTABLE_LETTER_FILES}
                  invalidFileMessage='Please upload only valid image files.'
                  handleFileUpload={handleSetImage}
                />
                <StatusMessage status='error' message={errors?.image ?? ''} />
              </div>
            </label>
          </div>

          <MarkdownEditor
            placeholder={'Enter your description'}
            handleChange={handleDescriptionChange}
            initialMarkdown={program?.description || ''}
          />

          <StatusMessage status='error' message={errors?.description ?? ''} />

          <div className='absolute bottom-6 flex justify-between w-[calc(100%_-_48px)] flex-col md:flex-row gap-y-4'>
            <div className='flex flex-col-reverse xxs:flex-row items-center justify-end gap-x-4 gap-y-2'>
              <Button
                variant='outlined'
                type='button'
                onClick={close}
                disabled={isUpdate ? isUpdatingProgram : isCreatingProgram}
                className={'w-full xxs:w-max'}
              >
                Cancel
              </Button>

              <Button type='submit' isLoading={isUpdate ? isUpdatingProgram : isCreatingProgram} className={'w-full xxs:w-max'}>
                Save
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddProgramModal

AddProgramModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.string,
  program: PropTypes.object,
}
