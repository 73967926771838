import PropTypes from 'prop-types'
import { StatIcon } from 'assets'

/**
 * The card that's displayed on some screens of the dashboard showing an icon, a label (representing a statistic) and the value of the statistic.
 */
const DashboardCard = ({ label, value, isActive, action }) => {
  return (
    <div
      className={`p-4 w-[265px] max-w-full ${isActive ? 'dashboard-card-container-active' : 'dashboard-card-container'}  ${action ? 'cursor-pointer' : ''}`}
      onClick={action}
      data-testid='dashboard-card'
    >
      <div className='flex items-center mb-3'>
        <StatIcon className='w-7 h-7' />
        <p className='m-2 font-semibold text-xs leading-5 text-grey/500'>{label}</p>
      </div>

      <p className='text-[23px] text-shades/black font-bold'>{value ?? '-'}</p>
    </div>
  )
}

export default DashboardCard

DashboardCard.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isActive: PropTypes.bool,
  action: PropTypes.func,
}
