import { alert, validate } from 'utilities'

import Button from './button'
import FileUploadField from './file-upload-field'
import InputGroup from './input-group'
import Modal from './modal'
import ModalCloseBtn from './modal-close-btn'
import PropTypes from 'prop-types'
import StatusMessage from './status-message'
import { useCreateMediaMutation } from 'services/media.service'
import { useHandleApiResponse } from 'hooks'
import { useState } from 'react'

const UploadMediaModal = ({ isOpen, close }) => {
  const [title, setTitle] = useState('')
  const [image, setImage] = useState(null)
  const [errors, setErrors] = useState({})

  const [createMedia, { data: createMediaResponse, isLoading: isCreatingMedia, ...response }] = useCreateMediaMutation()

  const clearForm = () => {
    setTitle('')
    setImage(null)
    setErrors({})
  }

  const handleCreateMediaSuccess = () => {
    alert.success('Media uploaded successfully.')
    clearForm()
    close()
  }

  useHandleApiResponse({
    data: createMediaResponse,
    handleSuccess: handleCreateMediaSuccess,
    ...response,
  })

  const handleSetImage = (file) => {
    setErrors((prev) => ({ ...prev, image: '' }))
    setImage(file)
  }

  const handleTitleChange = (value) => {
    setTitle(value)
    setErrors((prev) => ({ ...prev, title: '' }))
  }

  const handleSubmit = (ev) => {
    ev.preventDefault()

    const { errors, hasErrors } = validate.createMedia({ title, image })

    if (hasErrors) {
      setErrors(errors)
    } else {
      const formData = new FormData()
      formData.append('title', title)
      formData.append('image', image)

      createMedia(formData)
    }
  }

  return (
    <Modal isOpen={isOpen} close={close} dialogClassName={'!w-[480px]'}>
      <div>
        <ModalCloseBtn close={close} />

        <h3 className='text-base font-semibold leading-[28px] mb-3 text-[#222]'>Upload new media</h3>

        <form onSubmit={handleSubmit}>
          <InputGroup
            label={'Title'}
            inputProps={{ placeholder: 'Enter media title', value: title, onChange: handleTitleChange }}
            className={'mb-4'}
            statusMessageProps={{ status: 'error', message: errors?.title }}
          />

          <label className='form-label' htmlFor='media'>
            Upload Media{' '}
          </label>
          <FileUploadField
            acceptableFiles={ACCEPTABLE_LETTER_FILES}
            invalidFileMessage='Please upload only valid image files.'
            handleFileUpload={handleSetImage}
          />
          <StatusMessage status='error' message={errors?.image ?? ''} />

          <div className='flex flex-col-reverse xxs:flex-row items-center justify-end gap-x-4 mt-8 gap-y-2'>
            <Button variant='outlined' onClick={close} disabled={isCreatingMedia} className={'w-full xxs:w-max'}>
              Cancel
            </Button>

            <Button type='submit' isLoading={isCreatingMedia} className={'w-full xxs:w-max'}>
              Upload
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UploadMediaModal

UploadMediaModal.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
}

export const ACCEPTABLE_LETTER_FILES = ['image/png', 'image/jpeg']
