import { Link } from 'react-router-dom'
import propTypes from 'prop-types'

const NavbarLink = ({ label, path, containerBg, onClick, isInPage }) => {
  const handleClick = (e) => {
    onClick?.()
    e.stopPropagation()
  }

  const className = `${containerBg === 'light' ? 'text-[#333]' : 'text-white'} relative font-medium cursor-pointer  hover:bg-gray-800 px-4 py-2 rounded transition-colors duration-300 :hover:text-[#BF5540]`

  return (
    <li className='py-5'>
      {isInPage ? (
        <a
          href={path}
          onClick={handleClick} // Corrected path interpolation
          className={className}
        >
          {label}
        </a>
      ) : (
        <Link
          to={path}
          onClick={handleClick} // Corrected path interpolation
          className={className}
        >
          {label}
        </Link>
      )}
    </li>
  )
}

export default NavbarLink

NavbarLink.propTypes = {
  label: propTypes.string.isRequired,
  path: propTypes.string.isRequired,
  containerBg: propTypes.oneOf(['light', 'dark']),
  onClick: propTypes.func.isRequired,
  isInPage: propTypes.bool,
}
