import Loader from './loader'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

const SidebarItem = ({ className, icon, text, link, shouldDisplayOnlyIcon, isLoading, handleClick, disabled, cannotSelect, isInpageLink, close }) => {
  const content = (
    <div
      className={`${className} w-full rounded-lg hover:bg-grey/800 flex gap-x-[1.1044rem] ${
        shouldDisplayOnlyIcon ? 'w-11 h-11 justify-center' : 'w-[13rem] h-[2.875rem] pl-[.8538rem]'
      }  items-center `}
    >
      <span className=' w-5 h-5 flex items-center'>{icon}</span>
      {!shouldDisplayOnlyIcon && (
        <div className='flex items-center gap-x-2'>
          <p className=' capitalize text-shades/white text-sm leading-[1.375rem]'>{text}</p>
          {isLoading && <Loader />}
        </div>
      )}
    </div>
  )

  const classes = ({ isActive }) =>
    `w-full rounded-lg mb-1 block ${isActive && !cannotSelect ? 'bg-grey/800' : ''} ${shouldDisplayOnlyIcon ? 'justify-center items-center' : ''}`

  const linkNode = isInpageLink ? (
    <a href={link} className={classes} onClick={close}>
      {content}jd
    </a>
  ) : (
    <NavLink to={link} className={classes}>
      {content}
    </NavLink>
  )

  return link ? (
    linkNode
  ) : (
    <button className={classes({ isActive: false })} onClick={handleClick} disabled={disabled} type='button'>
      {content}
    </button>
  )
}

export default SidebarItem

SidebarItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  shouldDisplayOnlyIcon: PropTypes.bool,
  isLoading: PropTypes.bool,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  cannotSelect: PropTypes.bool,
  isInpageLink: PropTypes.bool,
  close: PropTypes.func,
}
