import { lazy } from 'react'

export const Home = lazy(() => import('./home'))
export const About = lazy(() => import('./about'))
export const Gallery = lazy(() => import('./gallery'))
export const ProgramsHome = lazy(() => import('./programs/home'))
export const ProgramsView = lazy(() => import('./programs/view'))
export const AdminLogin = lazy(() => import('./admin/login'))
export const AdminGallery = lazy(() => import('./admin/gallery'))
export const AdminProgramsHome = lazy(() => import('./admin/programs/home'))
export const AdminProgramsView = lazy(() => import('./admin/programs/view'))
export const NotFound = lazy(() => import('./not-found'))
