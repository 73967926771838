// import { ChevronLeft, ChevronRight } from 'react-feather' // Import arrow icons

// import Button from './button'
// import { Link } from 'react-router-dom'
// import React from 'react'
// import heroImage from '../assets/herobg.png'
// import { useSnapCarousel } from 'react-snap-carousel'
// import { useGetMedia } from 'hooks'

// const AdvancedCarousel = () => {
//   const { scrollRef, pages, activePageIndex, next, prev, goTo } = useSnapCarousel()
//   const { programs, isGettingPrograms, noProgramsFound, isProgramsFound } = useGetPrograms() // Use the useGetPrograms hook
//   // const { media, isGettingMedia, noMediaFound, isMediaFound } = useGetMedia()

//   // // Duplicate the hero image 12 times
//   // const carouselItems = Array.from({ length: 12 }).map((_, index) => (
//   //   <li key={index} className='flex-none w-4/12 mb-16 mr-4'>
//   //     <img src={heroImage} className='block w-full h-auto' alt={`Hero ${index + 1}`} />
//   //     <p className='text-center text-gray-700 mt-2'>Label {index + 1}</p> {/* Add label */}
//   //   </li>
//   // ))
//   useEffect(() => {
//     // Fetch programs when the component mounts
//     fetchPrograms()
//   }, []) // Empty dependency array ensures the effect runs only once

//   // Map fetched programs to carousel items
//   const carouselItems = programs.slice(0, 5).map((program, index) => (
//     <li key={index} className='flex-none w-4/12 mb-16 mr-4'>
//       {/* Assuming each program has a title and an image */}
//       <img src={program.imageUrl} className='block w-full h-auto' alt={`Program ${index + 1}`} />
//       <p className='text-center text-gray-700 mt-2'>{program.title}</p>
//     </li>
//   ))
//   return (
//     <div className='relative flex flex-col justify-center items-center px-10 mx-auto overflow-hidden'>
//       <ul ref={scrollRef} className='flex overflow-hidden scroll-snap-x' style={{ scrollSnapType: 'x mandatory' }}>
//         {carouselItems}
//       </ul>
//       <div className='absolute left-0 bottom-32 flex items-center ml-32 mb-7'>
//         <button className='mb-4 px-4 py-2 bg-white rounded-full flex items-center lg:mb-0' onClick={prev}>
//           <ChevronLeft />
//         </button>
//       </div>
//       <div className='absolute bottom-32 right-0 flex items-center mr-32 mb-7'>
//         <button className='mb-4 px-4 py-2 bg-white rounded-full flex items-center lg:mb-0' onClick={next}>
//           <ChevronRight />
//         </button>
//       </div>
//       <div className='absolute bottom-32 left-0 right-0 flex justify-center mb-7'>
//         {pages.map((_, index) => (
//           <button
//             key={index}
//             className={`w-2 h-2 bg-gray-300 rounded-full mx-1 ${index === activePageIndex ? 'bg-orange-500' : ''}`}
//             onClick={() => goTo(index)}
//           />
//         ))}
//       </div>
//       <Link to='/gallery'>
//         <Button className='my-14 relative'>View Gallery</Button>
//       </Link>
//     </div>
//   )
// }

// export default AdvancedCarousel
import { ChevronLeft, ChevronRight } from 'react-feather' // Import arrow icons
import Button from './button'
import { Link } from 'react-router-dom'
// import React, { useEffect } from 'react'
import { useSnapCarousel } from 'react-snap-carousel'
import { useGetMedia } from 'hooks'

const AdvancedCarousel = () => {
  const { scrollRef, pages, activePageIndex, next, prev, goTo } = useSnapCarousel()
  const { media, isGettingMedia, noMediaFound, isMediaFound } = useGetMedia()

  const carouselItems = media?.map(
    (mediaItem, index) =>
      isMediaFound && (
        <li key={index} className='flex-none mb-16 mr-4'>
          <img
            src={mediaItem.media_link}
            className='block object-cover h-[100px] w-[100px] sm:h-[250px] border border-solid border-[#eee] sm:w-[250px]'
            alt={`media ${index + 1}`}
          />
          <p className='text-center text-gray-700 mt-2'>{mediaItem.title}</p>
        </li>
      )
  )

  return (
    <div className='relative w-full flex flex-col justify-center items-center mx-auto overflow-hidden wrapper'>
      <ul ref={scrollRef} className='flex overflow-hidden scroll-snap-x' style={{ scrollSnapType: 'x mandatory' }}>
        {carouselItems}
      </ul>
      <div className='absolute left-0 bottom-32 flex items-center ml-32 mb-7'>
        <button className='mb-4 px-4 py-2 bg-white rounded-full flex items-center lg:mb-0' onClick={prev}>
          <ChevronLeft />
        </button>
      </div>
      <div className='absolute bottom-32 right-0 flex items-center mr-32 mb-7'>
        <button className='mb-4 px-4 py-2 bg-white rounded-full flex items-center lg:mb-0' onClick={next}>
          <ChevronRight />
        </button>
      </div>
      <div className='absolute bottom-32 left-0 right-0 flex justify-center mb-7'>
        {pages?.map((_, index) => (
          <button
            key={index}
            className={`w-2 h-2 bg-gray-300 rounded-full mx-1 ${index === activePageIndex ? 'bg-orange-500' : ''}`}
            onClick={() => goTo(index)}
          />
        ))}
      </div>
      <Link to='/gallery'>
        <Button className='my-14 relative'>View Gallery</Button>
      </Link>
    </div>
  )
}

export default AdvancedCarousel
