import { Logo, NavbarLink } from 'components'

import Hamburger from './hamburger'
import PropTypes from 'prop-types'
import { routes } from 'routes'

const Navbar = ({ containerBg }) => {
  return (
    <div
      className={`flex justify-between items-center py-5 relative z-[293398] ${containerBg === 'light' ? 'bg-transparent' : 'bg-transparent'} text-white z-50`}
    >
      {/* Logo on the left */}
      <div className='flex items-center'>
        <Logo containerBg={containerBg} />
      </div>

      {/* Navigation items on the right */}
      <div className='hidden md:flex'>
        <ul className='flex flex-row gap-x-[36px] z-10 relative right-0'>
          <NavbarLink label='Home' path={routes.home} containerBg={containerBg} />
          <NavbarLink label='About us' path={routes.about} containerBg={containerBg} />
          <NavbarLink label='Programs' path={routes.programs.home} containerBg={containerBg} />
          <NavbarLink label='Gallery' path={routes.gallery} containerBg={containerBg} />
          <NavbarLink label='Contact us' path='#contactus' containerBg={containerBg} isInPage />
        </ul>
      </div>

      <div className=' relative md:hidden'>
        <Hamburger containerBg={containerBg} />
      </div>
      {/* <SidebarLayout toggle={sidebarToggle} setToggle={toggleSideBar}></SidebarLayout> */}
    </div>
  )
}

Navbar.propTypes = {
  containerBg: PropTypes.string.isRequired,
  hamburgerCol: PropTypes.bool.isRequired,
}

export default Navbar
