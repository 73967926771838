import { baseService } from './base.service'

export const adminService = baseService.injectEndpoints({
  endpoints: (builder) => ({
    getAdminProfile: builder.query({
      query: () => '/admins/profile/',
    }),
  }),
})

export const { useGetAdminProfileQuery } = adminService
