import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './../constants/auth.constants'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

import { BASE_API_URL } from 'constants/api.constants'
import { cookies } from 'utilities'
import { routes } from 'routes'
import { setTokens } from '../redux/slices/auth.slice'
import { toast } from 'react-toastify'

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}`,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().authSlice.accessToken

    // If we have a token set in state, let's assume that we should be passing it.
    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`)
    }

    return headers
  },
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && (result.error.status === 401 || result.error.status === 403)) {
    console.log('madmannn')
    const refreshToken = cookies.get(REFRESH_TOKEN_KEY) || ''
    if (!refreshToken && api?.endpoint !== 'login') {
      toast.error('Your session has expired. Please sign in again')
      window.location.href = routes.admin.login
      cookies.remove(ACCESS_TOKEN_KEY)
      cookies.remove(REFRESH_TOKEN_KEY)
    }

    if (refreshToken && api?.endpoint !== 'login') {
      console.log('refreshing token')
      console.log({ args, api, extraOptions })
      const refreshResult = await baseQuery(
        {
          url: `${BASE_API_URL}/admins/refresh/`,
          method: 'PATCH',
          body: { refresh_token: refreshToken },
        },
        api,
        extraOptions
      )

      if (refreshResult?.data) {
        const accessToken = refreshResult.data?.payload?.access_token
        const refreshToken = refreshResult.data?.payload?.refresh_token
        api.dispatch(setTokens({ accessToken, refreshToken }))
        result = await baseQuery({ ...args, headers: { Authorization: `Bearer ${accessToken}` } }, api, extraOptions)
      } else {
        toast.error('Your session has expired. Please sign in again')
        window.location.href = routes.admin.login
      }
      console.log({ refreshResult, refreshToken })
    }
  }
  return result
}

export const baseService = createApi({
  reducerPath: 'baseService',
  refetchOnReconnect: true,
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Media', 'Programs', 'Program'],
  endpoints: () => ({}),
})
