import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

import PropTypes from 'prop-types'

const DocumentViewer = ({ link, className }) => {
  const docs = [{ uri: link }]

  return (
    <div className={`h-[700px] max-h-[60vh] overflow-y-auto document-viewer ${className}`} data-testid='document-viewer'>
      <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} />
    </div>
  )
}

export default DocumentViewer

DocumentViewer.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
}
