/**
 * Determine the type of a file based on its extension.
 *
 * @param {string} fileExtension - The file extension to be checked (e.g., 'pdf', 'jpg', 'docx').
 * @returns {string} The type of the file ('pdf', 'image', 'office', or 'unknown').
 */
const getFileType = (fileExtension) => {
  const pdfExtensions = ['pdf']
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif']
  const officeExtensions = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']

  if (pdfExtensions.includes(fileExtension.toLowerCase())) {
    return 'pdf'
  } else if (imageExtensions.includes(fileExtension.toLowerCase())) {
    return 'image'
  } else if (officeExtensions.includes(fileExtension.toLowerCase())) {
    return 'office'
  } else {
    return 'unknown'
  }
}

export default getFileType
