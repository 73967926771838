import { baseService } from './base.service'

const programService = baseService.injectEndpoints({
  endpoints: (builder) => ({
    getPrograms: builder.query({
      query: () => '/programs',
      providesTags: ['Programs'],
    }),

    getProgram: builder.query({
      query: (id) => `/programs/${id}/`,

      // provide tag of program with id
      providesTags: [{ type: 'Program', id: (id) => id }],
    }),

    createProgram: builder.mutation({
      query: (body) => ({
        url: '/programs/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Programs'],
    }),

    updateProgram: builder.mutation({
      query: ({ id, body }) => ({
        url: `/programs/${id}/`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Programs', { type: 'Program', id: (id) => id }],
    }),

    deleteProgram: builder.mutation({
      query: (id) => ({
        url: `/programs/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Programs', { type: 'Program', id: (id) => id }],
    }),
  }),
})

export const { useCreateProgramMutation, useGetProgramQuery, useGetProgramsQuery, useDeleteProgramMutation, useUpdateProgramMutation } =
  programService
