const routes = {
  home: '/',
  gallery: '/gallery',
  about: '/about',
  programs: {
    home: '/programs/',
    view: '/programs/:programId',
  },

  // admin facing routes.
  admin: {
    home: '/admin',
    login: '/admin/login',
    gallery: '/admin/gallery',
    programs: {
      home: '/admin/programs',
      view: '/admin/programs/:programId',
    },
  },

  notFound: '*',
}

export { routes }
