import { useGetMediaQuery } from 'services/media.service'
import useHandleApiResponse from './useHandleApiResponse'
import { useState } from 'react'

const useGetMedia = () => {
  const [media, setMedia] = useState([])
  const { isFetching: isGettingMedia, ...response } = useGetMediaQuery()

  const handleSuccess = (data) => {
    setMedia(data?.data?.payload?.media)
  }

  useHandleApiResponse({ ...response, handleSuccess })

  const noMediaFound = !isGettingMedia && !media?.length
  const isMediaFound = !isGettingMedia && media?.length > 0

  return { media, isGettingMedia, noMediaFound, isMediaFound }
}

export default useGetMedia
