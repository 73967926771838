import React, { Suspense, useState } from 'react'
import { useDisclosure, useListenForOutsideClicks } from 'hooks'

import { Loader } from 'components'
import NavLayout from './nav-layout'
import { Outlet } from 'react-router'
import SidebarLayout from './sidebar-layout'

const DashboardLayout = () => {
  const { isOpen: isSidebarOpenForMobile, open: openSidebarForMobile, close: closeSidebarForMobile } = useDisclosure()

  const { elementRef: sidebarLayoutRef } = useListenForOutsideClicks(closeSidebarForMobile)

  const [sidebarToggle, setSidebarToggle] = useState(false)

  const toggleSideBar = () => {
    sidebarToggle ? setSidebarToggle(false) : setSidebarToggle(true)
  }

  return (
    <div className=' flex w-full h-[100dvh] overflow-x-hidden max-w-[100vw]'>
      {/* <div className={isSidebarOpenForMobile ? 'block' : 'hidden md:block'} ref={sidebarLayoutRef}> */}
      <div
        className={`${isSidebarOpenForMobile ? 'block translate-x-0' : '-translate-x-[16rem] md:block md:translate-x-0'} z-[100]`}
        ref={sidebarLayoutRef}
      >
        {/* <div ref={ref as MutableRefObject<HTMLDivElement | null>}> */}
        <SidebarLayout toggle={sidebarToggle} setToggle={toggleSideBar} />
        {/* </div> */}
      </div>

      <div className=' w-full h-[100dvh] overflow-y-auto'>
        <NavLayout
          openSidebarForMobile={() => {
            openSidebarForMobile()
          }}
        />

        {/* the contents wrapped by the navbar and sidebar */}
        <div id='portal-root' className='min-h-[calc(100%_-_60px)] relative overflow-x-hidden'>
          <div className='main-padding'>
            <Suspense fallback={<Loader className='h-[100dvh]' />}>
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DashboardLayout
