import Input from './input'
import PropTypes from 'prop-types'
import StatusMessage from './status-message'

const InputGroup = ({ statusMessageProps, inputProps, label, labelClassName, className, register, optional, extraLabelInfo }) => {
  return (
    <div className={`${className ?? ''} relative`}>
      {label ? (
        <label htmlFor={inputProps?.id} className={` form-label ${labelClassName ?? ''}`}>
          {label}

          {extraLabelInfo && <i className='text-[0.625rem] inline-block ml-[2px]'>({extraLabelInfo})</i>}

          {optional && <i className='text-[0.625rem] inline-block ml-[2px]'>(Optional)</i>}
        </label>
      ) : null}

      <Input {...inputProps} {...register} className='mb-[.125rem]' />
      {statusMessageProps ? <StatusMessage {...statusMessageProps} /> : null}
    </div>
  )
}

export default InputGroup

InputGroup.propTypes = {
  statusMessageProps: PropTypes.object,
  inputProps: PropTypes.object,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.object,
  optional: PropTypes.bool,
  extraLabelInfo: PropTypes.string,
}
