/**
 * Adds commas to a numeric value to separate every third digit.
 *
 * @param {number} num - The numeric value to format.
 * @returns {string} A string representation of the number with commas separating every third digit.
 *
 * @example
 *
 * // returns "1,234,567"
 * addCommas(1234567);
 *
 * // returns "5,678.90"
 * addCommas(5678.9);
 */
const addCommas = (num) => {
  num = Number(num)
  if (isNaN(num)) return ''

  return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : ''
}

export default addCommas
