/**
 * returns the file extension of a file url.
 * @param fileUrl
 * @returns
 */
const getFileExtension = (fileUrl) => {
  return fileUrl.split('.').pop()?.toLowerCase() ?? ''
}

export default getFileExtension
