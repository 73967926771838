import { useGetAdminProfileQuery } from 'services/admin.service'

const useGetAdminProfile = () => {
  const { data: adminProfileResponse, isFetching: isGettingAdminProfile } = useGetAdminProfileQuery()

  const payload = adminProfileResponse?.data?.payload?.data
  const role = payload?.role
  const email = payload?.email

  return { isGettingAdminProfile, role, email }
}

export default useGetAdminProfile
