import { useDisclosure, useHandleApiResponse } from 'hooks'

import AddProgramModal from './add-program-modal'
import Button from './button'
import DeleteConfirmationModal from './delete-confirmation-modal'
import Loader from './loader'
import PropTypes from 'prop-types'
import { alert } from 'utilities'
import { avatarIcon } from 'assets'
import { routes } from 'routes'
import string from 'utilities/string'
import { useDeleteProgramMutation } from 'services/program.service'
import { useNavigate } from 'react-router'

const ViewProgram = ({ program, isAdmin, isGettingProgram }) => {
  const navigate = useNavigate()

  const { isOpen: isDeleteModalOpen, open: openDeleteModal, close: closeDeleteModal } = useDisclosure()

  const { isOpen: isUpdateModalOpen, open: openUpdateModal, close: closeUpdateModal } = useDisclosure()

  const [deleteProgram, { isLoading: isDeleting, ...response }] = useDeleteProgramMutation()

  const handleDelete = () => {
    deleteProgram(program?.id)
  }

  const handleDeleteSuccess = () => {
    alert.success('Program deleted successfully.')
    closeDeleteModal()
    navigate(routes.admin.programs.home)
  }

  useHandleApiResponse({
    handleSuccess: handleDeleteSuccess,
    ...response,
  })

  const { html: htmlDescription } = string.extractTextFromMarkdown(program?.description)

  return (
    <div className='flex flex-col items-center justify-center  w-[800px] max-w-full mx-auto'>
      {isGettingProgram && <Loader />}

      {program && !isGettingProgram && (
        <div className='mt-2 xs:mt-10 w-full'>
          <h1 className=' font-medium mb-5 text-[24px] xxs:text-[28px] sm:text-[36px] leading-8 xxs:leading-10 text-[#181A2A]'>{program?.title}</h1>
          <div className='w-full flex-col sm:flex-row gap-y-4 flex mb-8 justify-between '>
            <div className='gap-x-6 flex items-center'>
              <div className='gap-x-2 flex items-center'>
                <img src={avatarIcon} alt='Display' className='h-7 w-7' />
                <p className='text-sm font-medium text-[#696A75]'>Admin</p>
              </div>

              <p className='text-sm text-[#696A75]'>August 20, 2022</p>
            </div>

            <div className='flex gap-x-4'>
              {!isAdmin && (
                <a href={program?.apply_link} target='_blank' rel='noreferrer'>
                  <Button>Apply Now</Button>
                </a>
              )}

              {isAdmin && <Button onClick={openUpdateModal}>Edit</Button>}

              {isAdmin && (
                <Button variant='outlined' onClick={openDeleteModal}>
                  Delete
                </Button>
              )}
            </div>
          </div>
        </div>
      )}

      {program && !isGettingProgram && (
        <div className=' items-center justify-center flex flex-col w-full'>
          <img src={program?.cover_image_link} alt='View Program' className='mb-4 rounded-md h-[240px] sm:h-[462px] object-cover w-full' />

          <div className='text-gray-700 text-left mt-8 mb-16 w-full program-markdown' dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>

          {!isAdmin && (
            <a href={program?.apply_link} className=' flex justify-center'>
              <Button className='self-center mb-20'>Apply Now</Button>
            </a>
          )}
        </div>
      )}

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        close={closeDeleteModal}
        itemLabel='program'
        isDeleting={isDeleting}
        handleDelete={handleDelete}
      />

      <AddProgramModal action='update' isOpen={isUpdateModalOpen} program={program} close={closeUpdateModal} />
    </div>
  )
}

export default ViewProgram

ViewProgram.propTypes = {
  program: PropTypes.object,
  isAdmin: PropTypes.bool,
  isGettingProgram: PropTypes.bool,
}
