import isValidDateTimeString from './is-valid-datetime-string'

/**
 * Converts a date string to the format "MMM DD, YYYY".
 *
 * @param {string} dateStr - The date string to format, in ISO 8601 format, e.g, '2023-02-21T09:59:42.307Z'.
 * @returns {string} The formatted date string, e.g, "May 16, 2021".
 */
const formatDate = (dateStr) => {
  if (!dateStr || !isValidDateTimeString(dateStr)) return ''

  const date = new Date(dateStr)
  const year = date.getFullYear()
  const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date)
  const day = String(date.getDate()).padStart(2, '0')

  return `${month} ${day}, ${year} `
}

export default formatDate
