import { Link, useNavigate } from 'react-router-dom'

import PropTypes from 'prop-types'
import { date } from 'utilities'
import rightarrow from '../assets/arrow-right.svg'
import string from 'utilities/string'

const ProgramCard = ({ cover_image_link, title, description, apply_link, created_at, isAdmin, id }) => {
  const navigate = useNavigate()

  const viewProgram = () => {
    navigate(isAdmin ? `/admin/programs/${id}` : `/programs/${id}`)
  }

  const { plainText: plainDescriptionText, html } = string.extractTextFromMarkdown(description)

  // console.log({ plainText, html }, 'akakak')

  return (
    <div className='flex flex-col items-left cursor-pointer' onClick={viewProgram}>
      <img src={cover_image_link} alt='Job' className='h-[280px] w-full rounded-md mb-[15px] object-cover border border-solid border-[#eee]' />

      <div>
        <p className='text-[#6C757D] text-xs font-normal mb-[15px]'>{date.formatDate(created_at)}</p>
        <h2 className='text-lg font-medium text-[#495057] leading-6 mb-[15px] line-clamp-2'>{title}</h2>

        <p className='text-[#6C757D] font-normal mb-[15px] text-xs leading-5 h-16 w-full line-clamp-3'>{plainDescriptionText}</p>

        {isAdmin ? null : (
          <Link to='/programs/:programId' className='flex items-center '>
            <a
              href={apply_link}
              className='flex items-center font-medium text-sm text-[#BF5540] hover:text-[#c78379] transition-colors duration-300 apply-link'
              target='_blank'
              rel='noreferrer'
            >
              Apply Now <img src={rightarrow} alt='Arrow' className='w-5 ml-2' />
            </a>
          </Link>
        )}
      </div>
    </div>
  )
}

ProgramCard.propTypes = {
  cover_image_link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  apply_link: PropTypes.func.isRequired,
  created_at: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  id: PropTypes.string,
}

export default ProgramCard
