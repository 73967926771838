import ProgramCard from './program-card'
import PropTypes from 'prop-types'

const ProgramCards = ({ items, isAdmin }) => (
  <div className='flex flex-wrap gap-x-[25px] gap-y-[20px] xs:gap-y-[30px]'>
    {items.map((item) => (
      <div key={item?.title} className='w-full sm:w-[calc(calc(100%_-_30px)_/_2)] lg:w-[calc(calc(100%_-_75px)_/_4)] max-w-full '>
        <ProgramCard {...item} isAdmin={isAdmin} />
      </div>
    ))}
  </div>
)
ProgramCards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    })
  ).isRequired,
  isAdmin: PropTypes.bool,
}

export default ProgramCards
