import Button from './button'
import { DeleteModalIcon } from 'assets'
import Modal from './modal'
import ModalCloseBtn from './modal-close-btn'
import PropTypes from 'prop-types'

const DeleteConfirmationModal = ({ isOpen, close, itemLabel, isDeleting, handleDelete }) => {
  return (
    <Modal isOpen={isOpen} close={close} dialogClassName={'w-[544px] max-w-full'}>
      <div>
        <ModalCloseBtn close={close} />

        <div className='flex gap-x-4 mb-8 flex-col sm:flex-row gap-y-2'>
          <DeleteModalIcon className='shrink-0 h-12 w-12' />

          <div>
            <h3 className='text-lg font-semibold leading-[28px] mb-4 text-[#222] capitalize'>Delete {itemLabel}</h3>

            <p className='text-sm text-[#838587]'>You are about to delete this {itemLabel}. Are you sure you want to continue with this action.</p>
          </div>
        </div>

        <div className='flex flex-col-reverse xxs:flex-row items-center justify-end gap-x-4 mt-8 gap-y-2'>
          <Button variant='outlined' onClick={close} disabled={isDeleting} className={'w-full xxs:w-max'}>
            Cancel
          </Button>

          <Button className={'w-full xxs:w-max'} onClick={handleDelete} isLoading={isDeleting}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteConfirmationModal

DeleteConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  itemLabel: PropTypes.string,
  isDeleting: PropTypes.bool,
  handleDelete: PropTypes.func,
}
