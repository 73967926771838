const validateCreateMedia = ({ title, image }) => {
  const errors = {}

  if (!title?.trim()) {
    errors.title = 'Title is required'
  }

  if (!image) {
    errors.image = 'Image is required'
  }

  return { errors, hasErrors: errors.title || errors.image }
}

export default validateCreateMedia
