import { useGetProgramQuery } from '../services/program.service'
import useHandleApiResponse from './useHandleApiResponse'
import { useState } from 'react'

const useGetProgram = (programId) => {
  const [program, setProgram] = useState(null)
  const { isFetching: isGettingProgram, ...response } = useGetProgramQuery(programId, { skip: !programId })

  const handleSuccess = (data) => {
    setProgram(data?.data?.payload?.program)
  }
  console.log({ program })

  useHandleApiResponse({ ...response, handleSuccess })

  return { program, isGettingProgram }
}

export default useGetProgram
