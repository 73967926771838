/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'

const useHandleApiResponse = ({ data, error, isSuccess, isError, handleSuccess }) => {
  // console.log({ data, error, isSuccess, isError, handleSuccess })

  useEffect(() => {
    if (isSuccess) {
      handleSuccess?.(data)
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (isError) {
      // alert.apiError(error)
    }
  }, [isError])
}

export default useHandleApiResponse
