import { InfoIcon } from 'assets'
import PropTypes from 'prop-types'

const StatusMessage = ({ message, status }) => {
  const statusColors = {
    error: 'text-error/500',
    neutral: 'text-neutral/500',
    warning: 'text-warning/500',
    success: 'text-success/500',
  }

  return message && status ? (
    <div className={`${statusColors[status]} flex items-center`}>
      <InfoIcon className={`${statusColors[status]} h-4 shrink-0`} />
      <p className='text-xs ml-2 leading-5'>{message}</p>
    </div>
  ) : null
}

export default StatusMessage

StatusMessage.propTypes = {
  message: PropTypes.string,
  status: PropTypes.oneOf(['error', 'neutral', 'warning', 'success']),
}
