import {
  AboutIcon,
  AccountSettingsIcon,
  CloseIconWhite,
  ContactIcon,
  DoubleArrowLeftIcon,
  GalleryIcon,
  HomeIcon,
  LogoutIcon,
  ProgramsIcon,
} from 'assets'
import { Logo, SidebarItem } from 'components'

import { useDisclosure } from 'hooks'
import PropTypes from 'prop-types'
import { routes } from 'routes'

const SidebarLayout = ({ isRight, setIsNavShorter, close }) => {
  const { isOpen: isWidthShorter, open: makeWidthShorter, close: makeWidthLonger } = useDisclosure()

  return (
    <div className={` ${isWidthShorter ? 'w-[5.75rem] px-4' : 'w-[16rem] px-6'} bg-grey/900 h-[100dvh] z-[99999999] pt-4  md:static absolute `}>
      <div className='relative'>
        <div className='flex justify-between items-center'>
          <div className='flex flex-col gap-y-2 items-center justify-center'>
            {!isRight && <Logo containerBg='dark' direction='y' withoutText={isWidthShorter} className={isWidthShorter ? 'h-[3rem] w-[3rem]' : ''} />}

            {isWidthShorter && (
              <button
                onClick={() => {
                  makeWidthLonger()
                  setIsNavShorter?.(false)
                }}
                className={`${isRight ? 'mb-3' : ''}`}
              >
                <DoubleArrowLeftIcon className={` w-6 h-6 ${isRight ? 'rotate-0' : 'rotate-180'}`} />
              </button>
            )}
          </div>

          {!isWidthShorter && (
            <div className={`flex w-full ${isRight ? 'justify-between items-center mb-3' : 'justify-end'}`}>
              {isRight && (
                <button className='text-white' onClick={close}>
                  <CloseIconWhite />
                </button>
              )}
              <button>
                <DoubleArrowLeftIcon
                  className={` w-6 h-6 ${isRight ? 'rotate-180' : ''}`}
                  onClick={() => {
                    setIsNavShorter?.(true)
                    makeWidthShorter()
                  }}
                />
              </button>
            </div>
          )}
        </div>
      </div>

      <div className=' grid gap-y-4'>
        {!isRight && <p className=' text-grey/300 text-[.75rem] leading-5 mt-[1.875rem]'>Menu</p>}

        {isRight && (
          <div>
            {/* home, accessible to all roles. */}
            <SidebarItem
              icon={isRight ? <HomeIcon /> : <ProgramsIcon />}
              text={'Home'}
              link={isRight ? routes.home : routes.admin.programs.home}
              shouldDisplayOnlyIcon={isWidthShorter}
            />
          </div>
        )}

        {isRight && (
          <div>
            {/* home, accessible to all roles. */}
            <SidebarItem
              icon={isRight ? <AboutIcon /> : <ProgramsIcon />}
              text={'About us'}
              link={isRight ? routes.about : routes.home}
              shouldDisplayOnlyIcon={isWidthShorter}
            />
          </div>
        )}

        <div>
          <SidebarItem
            icon={<ProgramsIcon />}
            text={'Programs'}
            link={isRight ? routes.programs.home : routes.admin.programs.home}
            shouldDisplayOnlyIcon={isWidthShorter}
          />
        </div>

        <div>
          {/* home, accessible to all roles. */}
          <SidebarItem
            icon={<GalleryIcon />}
            text={'Gallery'}
            link={isRight ? routes.gallery : routes.admin.gallery}
            shouldDisplayOnlyIcon={isWidthShorter}
          />
        </div>

        {isRight && (
          <div>
            {/* Link to About Us section */}
            <SidebarItem
              icon={<ContactIcon />}
              text={'Contact us'}
              link={'#contactus'}
              shouldDisplayOnlyIcon={isWidthShorter}
              cannotSelect
              isInpageLink
              close={close}
            />
          </div>
        )}

        <hr className={`border-grey/700 border-[.0625rem] ${isWidthShorter ? 'w-[60px]' : 'w-[13rem]'}`} />

        {!isRight && <SidebarItem icon={<AccountSettingsIcon />} text={'Account Settings'} shouldDisplayOnlyIcon={isWidthShorter} />}
      </div>

      {!isRight && (
        <div className='absolute bottom-12'>
          <SidebarItem
            icon={<LogoutIcon className='text-white' />}
            // text={isLoggingOut ? 'Logging Out' : 'Log Out'}
            text='Log Out'
            shouldDisplayOnlyIcon={isWidthShorter}
            handleClick={() => {
              return
            }}
          />
        </div>
      )}
    </div>
  )
}

export default SidebarLayout

SidebarLayout.propTypes = {
  isRight: PropTypes.bool.isRequired,
  setIsNavShorter: PropTypes.func,
  close: PropTypes.func,
}
