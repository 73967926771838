/**
 *
 * formats the size of a JS File object `file.size`.
 *
 * @param bytes - the size of the file in bytes
 * @param decimalPoint - number of decimal points to round the formated value to. Optional.
 * @returns {string} - the formatted size,
 *
 * @example
 * formatBytes(2000);       // 2 KB
 * formatBytes(2234);       // 2.23 KB
 * formatBytes(2234, 3);    // 2.234 KB
 */

const formatSize = (bytes, decimalPoint) => {
  if (!bytes) return '0 Bytes'
  const k = 1000,
    dm = decimalPoint || 2,
    sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export default formatSize
