import { useGetProgramsQuery } from './../services/program.service'
import useHandleApiResponse from './useHandleApiResponse'
import { useState } from 'react'

const useGetPrograms = () => {
  const [programs, setPrograms] = useState([])
  const { isFetching: isGettingPrograms, ...response } = useGetProgramsQuery()

  const handleSuccess = (data) => {
    setPrograms(data?.data?.payload?.data)
  }

  useHandleApiResponse({ ...response, handleSuccess })

  const noProgramsFound = !isGettingPrograms && !programs?.length
  const isProgramsFound = !isGettingPrograms && programs?.length > 0

  return { programs, isGettingPrograms, noProgramsFound, isProgramsFound }
}

export default useGetPrograms
