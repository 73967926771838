import { baseService } from './base.service'

const mediaService = baseService.injectEndpoints({
  endpoints: (builder) => ({
    getMedia: builder.query({
      query: () => '/media/',
      providesTags: ['Media'],
    }),

    createMedia: builder.mutation({
      query: (body) => ({
        url: '/media/',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Media'],
    }),

    deleteMedia: builder.mutation({
      query: (id) => ({
        url: `/media/${id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Media'],
    }),
  }),
})

export const { useCreateMediaMutation, useDeleteMediaMutation, useGetMediaQuery } = mediaService
