const validateLogin = ({ email, password }) => {
  let errors = {}

  const emailValue = email.trim()
  const passwordValue = password.trim()
  if (!emailValue || !passwordValue) {
    errors = {
      ...errors,
      email: emailValue ? '' : 'Email is required',
      password: passwordValue ? '' : 'Password is required',
    }
  }

  // if valid email regex.
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  if (emailValue && !emailRegex.test(emailValue)) {
    errors = { ...errors, email: 'Invalid email' }
  }

  const hasErrors = errors.email || errors.password

  return { errors, hasErrors }
}

export default validateLogin
