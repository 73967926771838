import DOMPurify from 'dompurify'
import { marked } from 'marked'

const extractTextFromMarkdown = (markdownString) => {
  if (!markdownString) return { plainText: '', html: '' }
  // 1. Parse Markdown into HTML
  const html = DOMPurify.sanitize(marked.parse(markdownString))

  // 2. Create a temporary DOM element
  const tempDiv = document.createElement('div')
  tempDiv.innerHTML = html

  // 3. Extract plain text content
  const plainText = tempDiv.textContent || tempDiv.innerText

  // 4. Clean up and return
  return { plainText: plainText.trim(), html }
}

export default extractTextFromMarkdown
