/**
 * fetch the value of a specified key in
 * @param key
 * @returns
 */
export const get = (key) => {
  const keyEQ = key + '='
  const ca = document.cookie.split(';')
  for (const i of ca) {
    let c = i
    while (c.startsWith(' ')) c = c.substring(1, c.length)
    if (c.startsWith(keyEQ)) return c.substring(keyEQ.length, c.length)
  }
  return null
}

export default get
