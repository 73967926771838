import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  dashboardHeader: '',
}

export const uiSlice = createSlice({
  name: 'uiSlice',
  initialState,
  reducers: {
    setDashboardHeader: (state, action) => {
      state.dashboardHeader = action.payload
    },
  },
})

export const { setDashboardHeader } = uiSlice.actions

export default uiSlice.reducer

export const selectDashboardHeader = (state) => state.uiSlice.dashboardHeader ?? ''
