import { Outlet } from 'react-router'
import React from 'react'

const ProtectedRoute = () => {
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default ProtectedRoute
