/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-no-comment-textnodes */
import coreval from '../assets/coreval.png'
import mission from '../assets/mission.png'
import vision from '../assets/vision.png'

const CoreValue = () => {
  return (
    <div className='gap-x-4 justify-center mb-20 flex flex-row flex-wrap gap-y-[20px] xs:gap-y-[30px]'>
      <div className='flex flex-col items-left p-10 bg-[#EBEBEB] rounded-md w-full sm:w-[calc(calc(100%_-_30px)_/_2)] lg:w-[calc(calc(100%_-_60px)_/_3)] max-w-full'>
        <img src={mission} alt='Job' className='w-full h-auto mb-4 mx-auto' />
        <div className='h-1 w-8 bg-[#000000]'></div>
        <p className='text-[#000000] text-2xl font-medium mb-4'>Mission</p>
        <p className='text-sm font-normal text-[#484848] leading-6 mb-4'>
          Our mission is to alleviate human struggles by providing aid, conducting outreaches, and offering scholarships. We are dedicated to
          empowering young people through education and humanitarian services, inspired by our passion for God and humanity.
        </p>
      </div>
      <div className='flex flex-col items-left p-10 bg-[#EBEBEB] rounded-md w-full sm:w-[calc(calc(100%_-_30px)_/_2)] lg:w-[calc(calc(100%_-_60px)_/_3)] max-w-full'>
        <div className='h-1 w-8 bg-[#000000] mt-14'></div>
        <p className='text-[#000000] text-2xl font-medium mb-4'>Vision</p>
        <p className='text-sm font-normal text-[#484848] leading-6 mb-4'>
          We aim to create a world where everyone connected to our platform, regardless of gender, race, background, or circumstances, can realize
          their full potential and live a life of purpose and fulfillment.
        </p>
        <img src={vision} alt='Job' className='w-70 h-auto mb-4 mx-auto' />
      </div>
      <div className='flex flex-col items-left p-10 bg-[#EBEBEB] rounded-md w-full sm:w-[calc(calc(100%_-_30px)_/_2)] lg:w-[calc(calc(100%_-_60px)_/_3)] max-w-full'>
        <img src={coreval} alt='Job' className='w-70 h-auto mb-4 mx-auto' />
        <div className='h-1 w-8 bg-[#000000]'></div>
        <p className='text-[#000000] text-2xl font-medium mb-4'>Core Value</p>
        <p className='text-sm font-normal text-[#484848] leading-6 mb-4'>
          Godliness, Integrity, Excellence, Compassion and Empowerment. We are guided by faith, honesty, and a commitment to the highest standards,
          driven by love for humanity and belief in every individual's potential. We serve all, regardless of nation, race, gender, background, or
          circumstances.
        </p>
      </div>
    </div>
  )
}

export default CoreValue
