/**
 * checks if a string is a valid datetime string.
 */

// check if date string is invalid.
const isValidDateTimeString = (dateStr) => {
  if (!dateStr) return false

  const date = new Date(dateStr)
  return !isNaN(date?.getTime())
}

export default isValidDateTimeString
