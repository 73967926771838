import { EmptyBoxIcon } from 'assets'
import PropTypes from 'prop-types'

const NoItemsFound = ({ itemLabel }) => {
  return (
    <div className='flex flex-col justify-center items-center gap-y-2 my-20'>
      <EmptyBoxIcon className='h-64' />

      <p className=' text-sm font-medium text-blue-gray-700 text-center'>There are no {itemLabel ?? 'items'} found</p>
    </div>
  )
}

export default NoItemsFound

NoItemsFound.propTypes = {
  itemLabel: PropTypes.string,
}
