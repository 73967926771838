import Button from './button'
import Facebook from '../assets/fb.png'
import Instagram from '../assets/instagram.png'
import React from 'react'
import Youtube from '../assets/yt.png'
import brandlogos from '../assets/brand.png'
import logoGrey from '../assets/logo-grey.svg'
import twitter from '../assets/twitter.png'
import { Link } from 'react-router-dom'
import { routes } from 'routes'

const Footer = () => {
  return (
    <div className='bg-[#22242A] flex flex-col md:flex-row md:justify-evenly wrapper' id='contactus'>
      {/* <div className='flex bg-[#22242A] text-white'> */}
      <div className='w-full bg-[#17181C] mt-16 rounded-lg shadow-lg py-8 px-6 md:w-[392px] md:my-16'>
        <p className='text-[#BF5540] text-xs font-medium'>FEEDBACK</p>
        <p className='text-[#FFFFFF66] text-base sm:text-3xl font-normal mt-10'>Have Something to Say?</p>
        <p className='text-white text-base sm:text-3xl font-normal mb-8'>Send us a message</p>
        <form data-netlify='true' name='Hill Emissaries Contact Form'>
          <div className='mb-4'>
            <input
              type='text'
              name='Name'
              placeholder='Name'
              className=' text-xs w-full py-2 px-4 text-white bg-[#17181C] border-solid border border-[#FFFFFF3D] rounded-lg focus:outline-none focus:border-[#FFFFFF99] sm:text-sm'
            />
          </div>
          <div className='mb-4'>
            <input
              type='email'
              name='Email'
              placeholder='Email'
              className='bg-[#17181C] text-white border border-[#FFFFFF3D] border-solid !rounded-lg focus:outline-none !focus:border-[#FFFFFF99] text-xs w-full py-2 px-4  sm:text-sm'
            />
          </div>
          <div className='mb-4'>
            <textarea
              name='Message'
              placeholder='Your Message'
              rows='4'
              className='text-xs text-white w-full py-2 px-4 bg-[#17181C] border border-[#FFFFFF3D] rounded-lg focus:outline-none  focus:border-[#FFFFFF99] sm:text-sm'
            ></textarea>
          </div>
          <Button className=' text-xs hover:bg-opacity-90 transition duration-300 mt-5 sm:text-sm' type='submit'>
            Send Message
          </Button>
        </form>
        <p className='text-[#FFFFFF66]  text-xs font-normal mt-40'>Privacy</p>
      </div>
      {/* </div> */}
      <div>
        <div className='w-full my-8 md:w-[704px] py-8 px-6 md:my-16'>
          <div className='flex flex-row justify-between align-top '>
            <div className='flex flex-col'>
              <p className='text-[#BF5540] text-xs font-medium mb-6'>INFO</p>
              <Link to={routes.home} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                Home
              </Link>

              <Link to={routes.programs.home} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                Programs
              </Link>

              <Link to={routes.about} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                About Us
              </Link>

              <Link to={routes.gallery} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                Gallery
              </Link>

              <Link to={routes.admin.login} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                Login as admin
              </Link>
            </div>

            <div className='flex flex-col'>
              <p className='text-[#BF5540] text-xs font-medium mb-6'>ABOUT US</p>
              <Link to={routes.gallery} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                Gallery
              </Link>
              <Link to={routes.about} className='text-xs cursor-pointer text-[#FFFFFF66] sm:text-sm font-normal mb-2 hover:text-[#BF5540]'>
                About Us
              </Link>
            </div>
            <img className='hidden h-20 w-auto sm:h-20 sm:flex sm:w-auto' src={logoGrey} alt='imgglogo'></img>
          </div>

          <div className='flex flex-col mt-14'>
            <p className='text-[#BF5540] text-xs font-medium mb-6'>CONTACT US</p>
            <p className='text-xs text-[#FFFFFF66] sm:text-sm font-normal mb-2'>+18138347991</p>
            <a href='mailto:Info@hillemissaries.com' className='text-xs text-[#FFFFFF66] sm:text-sm font-normal cursor-pointer mb-2'>
              Info@hillemissaries.com
            </a>
            <p className='text-xs text-[#FFFFFF66] sm:text-sm font-normal mb-2'>Tampa, Florida, United States</p>
          </div>
          <div className='mt-14'>
            {/* <p className='text-[#BF5540] text-xs font-medium mb-6'>SUBSCRIPTION</p>
            <div className='pb-10'>
              <input
                type='email'
                placeholder='Email'
                className='text-xs w-full text-white py-2 px-4 bg-[#17181C] border border-solid border-[#FFFFFF3D] rounded-lg focus:outline-none focus:border-[#FFFFFF99] sm:text-sm'
              />
            </div> */}

            <div className='flex flex-col items-center sm:flex-row md:align-bottom sm:justify-between mt-40'>
              <div className='flex items-center space-x-4'>
                <a href='https://www.facebook.com/profile.php?id=61563027965675&mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'>
                  {' '}
                  <div className='w-10 h-10 flex items-center justify-center rounded-full border border-text-[#FFFFFF66] cursor-pointer'>
                    <img src={Facebook} alt='Facebook' className='w-4 h-4 ' />
                  </div>
                </a>
                <a href='https://www.facebook.com/profile.php?id=61563027965675&mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'>
                  {' '}
                  <div className='w-10 h-10 flex items-center justify-center rounded-full border bordertext-[#FFFFFF66] cursor-pointer'>
                    <img src={Instagram} alt='Instagram' className='w-4 h-4' />
                  </div>
                </a>
                <a href='https://www.facebook.com/profile.php?id=61563027965675&mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'>
                  {' '}
                  <div className='w-10 h-10 flex items-center justify-center rounded-full border border-text-[#FFFFFF66] cursor-pointer'>
                    <img src={Youtube} alt='Youtube' className='w-4 h-4' />
                  </div>
                </a>
                <a href='https://www.facebook.com/profile.php?id=61563027965675&mibextid=LQQJ4d' target='_blank' rel='noopener noreferrer'>
                  {' '}
                  <div className='w-10 h-10 flex items-center justify-center rounded-full border border-text-[#FFFFFF66] cursor-pointer'>
                    <img src={twitter} alt='Twitter' className='w-4 h-4' />
                  </div>
                </a>
              </div>
              <p className='text-xs sm:text-sm text-[#FFFFFF66] mt-8 sm:0 '>© {new Date().getFullYear()} — Copyright</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
