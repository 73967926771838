import validateCreateMedia from './create-media'
import validateCreateProgram from './create-program'
import validateLogin from './login'

const validate = {
  login: validateLogin,
  createMedia: validateCreateMedia,
  createProgram: validateCreateProgram,
}

export default validate
