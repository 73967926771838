import Loader from './loader'
import PropTypes from 'prop-types'

const Button = ({ isLoading, children, className, disabled, variant = 'filled', ...props }) => {
  return (
    <button
      disabled={disabled || isLoading}
      className={`py-2.5 px-8 ${variant === 'filled' ? 'bg-[#BF5540] text-white' : 'bg-transparent text-[#BF5540] border border-solid border-[#Bf5540]'} text-sm font-medium flex items-center justify-center gap-x-2 disabled:bg-grey/200 disabled:text-grey/400 rounded-[50px] ${className}`}
      {...props}
    >
      {children} {isLoading && <Loader variant='inline' />}
    </button>
  )
}

export default Button

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['filled', 'outlined']),
}
