import add from './add'
import get from './get'
import remove from './remove'

/**
 * provides utility functions to interact with the browser's cookies.
 */
const cookies = {
  get,
  add,
  remove,
}

export default cookies
