import Button from './button'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import arrowImage from '../assets/arrow.png'
import { routes } from 'routes'

const Hero = ({ header, subtitle, showCallToAction }) => {
  return (
    <div className='flex flex-col justify-center items-center h-full relative z-10 -mt-[64px] sm:-mt-[104px]'>
      <h1
        data-aos='fade-up'
        className='text-4xl sm:text-5xl sm:leading-[64px] font-medium text-white text-center leading-[50px] md:text-7xl mb-6 sm:mb-10'
      >
        {header}
      </h1>

      <p
        data-aos='fade-up'
        className='text-sm sm:text-base sm:leading-8 text-white leading-6 text-center font-normal md:text-lg max-w-[58.75rem] md:leading-[160%]'
      >
        {subtitle}
      </p>

      {showCallToAction && (
        <div
          className='flex flex-col pt-10 justify-center items-center sm:flex sm:flex-row w-full gap-x-4 gap-y-4'
          data-aos='fade-up'
          data-aos-delay='600'
        >
          <Link to={routes.programs.home} className='w-full sm:w-max'>
            <Button className='w-full sm:w-max'>View Programs</Button>{' '}
          </Link>

          <Link to={routes.about} className='w-full sm:w-max'>
            <Button className='bg-white !text-[#BF5540] w-full sm:w-max'>About us</Button>
          </Link>
        </div>
      )}

      <img src={arrowImage} alt='Arrow' className='absolute bottom-5 sm:bottom-20 left-1/2 translate-x-1/2 h-12 arrow-bounce' />
    </div>
  )
}

export default Hero

Hero.propTypes = {
  header: PropTypes.string,
  subtitle: PropTypes.string,
  showCallToAction: PropTypes.bool,
  showFullHeight: PropTypes.bool,
}

// className={`py-2.5 px-8 bg-[#BF5540] text-white text-sm font-medium rounded-[50px] ${className}`}
