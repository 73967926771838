import { MenuIcon, avatarIcon } from 'assets'

import PropTypes from 'prop-types'
import { SkeletonLoader } from 'components'
import { selectDashboardHeader } from './../redux/slices/ui.slice'
import { useGetAdminProfile } from 'hooks'
import { useSelector } from 'react-redux'

const NavLayout = ({ openSidebarForMobile }) => {
  const header = useSelector(selectDashboardHeader)

  const { isGettingAdminProfile, role, email } = useGetAdminProfile()

  return (
    <nav className=' w-full h-[3.75rem] border-b-[.0625rem] border-neutral-100 px-5 xsm:px-6 flex items-center justify-between'>
      <div className='flex'>
        <div className='flex items-center'>
          <MenuIcon className=' w-6 h-6 rounded-full md:hidden mr-2' onClick={openSidebarForMobile} />

          <h6 className='font-bold text-lg text-[#222]'>{header}</h6>
        </div>
      </div>

      <div className='flex items-center justify-end'>
        {isGettingAdminProfile ? (
          <SkeletonLoader />
        ) : (
          <div className=' mr-3 hidden xs:block'>
            <div>
              <p className=' text-shades/black font-semibold text-[.875rem] leading-[1.375rem]'>{email}</p>
              <p className=' text-grey/500 text-[.875rem] leading-5'>{role}</p>
            </div>
          </div>
        )}

        <div>
          <img src={avatarIcon} alt='profile' className='object-cover rounded-full w-7 h-7' data-testid='avatar-image' />
        </div>
      </div>
    </nav>
  )
}

export default NavLayout

NavLayout.propTypes = {
  openSidebarForMobile: PropTypes.func.isRequired,
  header: PropTypes.string,
}
