import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import React, { useEffect } from 'react'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { AdminGallery, AdminLogin, AdminProgramsHome, AdminProgramsView, Gallery, Home, ProgramsHome, ProgramsView, About } from './pages'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import DashboardLayout from 'layouts/dashboard-layout'
import { Loader } from 'components'
import NotFound from 'pages/not-found'
import ProtectedRoute from 'protected-route'
import { Suspense } from 'react'
import { ToastContainer } from 'react-toastify'
import { routes } from './routes'
import { selectAccessToken } from './redux/slices/auth.slice'
import { useSelector } from 'react-redux'

function App() {
  const accessToken = useSelector(selectAccessToken)
  useEffect(() => {
    AOS.init({
      delay: 200, // delay in milliseconds
      duration: 600, // animation duration in milliseconds
      once: false, // whether animation should happen only once
      mirror: false, // whether elements should animate out while scrolling past them
    })
  }, [])

  return (
    <div>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Routes>
            <Route path={routes.home} element={<Home />} />
            <Route path={routes.gallery} element={<Gallery />} />
            <Route path={routes.about} element={<About />} />
            <Route path={routes.programs.home} element={<ProgramsHome />} />
            <Route path={routes.programs.view} element={<ProgramsView />} />

            {/* admin pages. */}
            <Route path={routes.admin.login} element={<AdminLogin />} />

            <Route path={routes.admin.home} element={<Navigate to={accessToken ? routes.admin.programs.home : routes.admin.login} />} />

            <Route path={routes.admin.home} element={<Navigate to={routes.admin.login} />} />

            <Route path='/' element={<ProtectedRoute />}>
              <Route path='/' element={<DashboardLayout />}>
                <Route path={routes.admin.gallery} element={<AdminGallery />} />
                <Route path={routes.admin.programs.home} element={<AdminProgramsHome />} />
                <Route path={routes.admin.programs.view} element={<AdminProgramsView />} />
              </Route>
            </Route>
            <Route path={routes.notFound} element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </Suspense>

      <ToastContainer />
    </div>
  )
}

export default App
