import formatSize from './format-size'
import getFileExtension from './get-file-extension'
import getFileType from './get-file-type'
import toObjectUrl from './to-object-url'

const file = {
  getFileExtension,
  getFileType,
  formatSize,
  toObjectUrl,
}

export default file
