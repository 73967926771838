import { toast } from 'react-toastify'

const apiError = (error) => {
  console.error('API Error:', error)
  let errorMessage = 'An error occurred. Please try again later.'

  if (error?.data?.errors?.reason) {
    errorMessage = error?.data?.errors?.reason
  }

  toast.error(errorMessage)
}

export default apiError
