import { CloseIcon } from 'assets'
import PropTypes from 'prop-types'

const ModalCloseBtn = ({ close }) => {
  return (
    <button type='button' className='absolute right-6 top-6' onClick={close}>
      <CloseIcon className='text-grey/600 h-[16px] w-[16px]' />
    </button>
  )
}

export default ModalCloseBtn

ModalCloseBtn.propTypes = {
  close: PropTypes.func,
}
