import { useEffect, useRef, useState } from 'react'
/**
 * A hook that provides functions for implementing file upload (selection and drag n' drop).
 */

const useFileUpload = ({ acceptableFiles, invalidFileMessage, handleFileUpload, handleFilesUpload, multiple, defaultFile }) => {
  const inputRef = useRef(null)
  const [uploadedFile, setUploadedFile] = useState(null) // for one file upload.
  const [uploadedFiles, setUploadedFiles] = useState([]) // for multiple file uploads.

  // resets the state of the input field.
  const resetUploadState = () => {
    setUploadedFile(null)
    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  // prevents default drag events outcomes.
  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
  }

  // validates files, and calls the `handleFilesUpload` from calling component if passed, with the valid files. Also add the files to `uploadedFiles`. Used if the field accepts multiple files.
  const handleMultipleFiles = (files) => {
    for (const file of files) {
      if (validateFile(file, acceptableFiles)) {
        setUploadedFiles((prev) => [...prev, file])
      }
    }
  }

  // call `handleFilesUpload` on `uploadedFiles` each time it changes.
  useEffect(() => {
    if (handleFilesUpload) {
      handleFilesUpload(uploadedFiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFiles])

  // validates file, and calls the `handleFileUpload` from calling component if passed, with the valid file. Also add the files to `uploadedFile`. Used if the field does not accepts multiple files.
  const handleFile = (file) => {
    resetUploadState()

    if (validateFile(file, acceptableFiles)) {
      setUploadedFile(file)
    }
  }

  // call `handleFileUpload` on `uploadedFile` each time it changes.
  useEffect(() => {
    if (handleFileUpload && uploadedFile) {
      handleFileUpload(uploadedFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedFile])

  // sets a default file passed by the calling component.
  useEffect(() => {
    if (defaultFile) {
      handleFile(defaultFile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFile])

  // handle file dropping on the field. If field accepts multiple files, calls the `handleMultipleFiles` on the file list, else calls `handleFile` on the first file in the list.
  const handleFileDrop = (e) => {
    e.preventDefault()

    if (multiple) {
      const files = Array.from(e.dataTransfer.files)
      handleMultipleFiles(files)
    } else {
      if (e.dataTransfer.files?.length > 1) {
        alert('This field does not accept multiple files. Only the first file was uploaded!')
      }

      const file = e.dataTransfer.files[0]
      handleFile(file)
    }
  }

  // triggers the hidden input field's click event. Called when the custom field component is clicked.
  const selectFile = () => {
    if (inputRef.current) {
      inputRef.current.click()
    }
  }

  // called when a file is selected directly from the system's file explorer.
  const handleFilesSelected = () => {
    if (inputRef.current?.files) {
      if (multiple) {
        const files = Array.from(inputRef.current?.files)
        handleMultipleFiles(files)
      } else {
        const file = inputRef.current?.files[0]
        handleFile(file)
      }
    }
  }

  // validates that file is acceptable.
  const validateFile = (file, acceptableFiles) => {
    if (!file) {
      alert(invalidFileMessage)
      // toast.error(invalidFileMessage)
      return false
    }

    // If the file is not an acceptable type
    if (acceptableFiles?.indexOf(file.type) === -1) {
      // toast.error(`${file.type || file.name} file is not allowed. ${invalidFileMessage}`)
      alert(`${file.type || file.name} file is not allowed. ${invalidFileMessage}`)
      return false
    }
    return true
  }

  // deletes a file in `uploadedFiles` at specified index.
  const deleteFileAtIndex = (index) => {
    setUploadedFiles((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)])
  }

  return {
    uploadedFile,
    uploadedFiles,
    inputRef,
    selectFile,
    handleDragOver,
    handleDragEnter,
    handleDragLeave,
    handleFileDrop,
    handleFilesSelected,
    resetUploadState,
    deleteFileAtIndex,
  }
}

export default useFileUpload
