import PropTypes from 'prop-types'

const Input = ({
  className,
  onChange,
  disabled = false,
  value,
  size = 'sm',
  type = 'text',
  placeholder = 'Enter here',
  LeftIcon,
  RightIcon,
  id,
  name,
  handleEventChange,
  handleBlur,
  ...props
}) => {
  // Generate Tailwind CSS classes based on the props
  const inputClasses = `
    ${className}
    flex rounded w-full font-normal border border-solid border-neutral/200 bg-transparent leading-[20px] text-xs text-grey/900
    focus:border-grey/900
    ${size === 'sm' ? ' px-4 py-2 ' : ''}
    ${size === 'md' ? ' py-[10px] px-4 ' : ''}
    ${size === 'lg' ? ' py-3 px-4 ' : ''}
    ${disabled ? ' !bg-neutral/50' : ' '}
    ${LeftIcon ? 'pl-10' : ''}
    `

  const handleChange = (event) => {
    onChange?.(event.target.value)
  }

  return (
    <div className='relative w-full'>
      {LeftIcon && <span className='absolute top-1/2 -translate-y-1/2 left-4'>{LeftIcon}</span>}

      {type === 'textarea' ? (
        <textarea
          placeholder={placeholder}
          className={inputClasses + ' h-[70px]'}
          value={value}
          name={name}
          disabled={disabled}
          // if `handleEventChange` was passed, use it, else use `handleChange`.
          onChange={handleEventChange || handleChange}
          id={id}
          {...props}
          onBlur={handleBlur}
        />
      ) : (
        <input
          type={type}
          className={inputClasses}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          name={name}
          // if `handleEventChange` was passed, use it, else use `handleChange`.
          onChange={handleEventChange || handleChange}
          id={id}
          {...props}
          onBlur={handleBlur}
        />
      )}

      {RightIcon && <span className='absolute top-1/2 -translate-y-1/2 right-4'>{RightIcon}</span>}
    </div>
  )
}

export default Input

Input.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  LeftIcon: PropTypes.node,
  RightIcon: PropTypes.node,
  id: PropTypes.string,
  name: PropTypes.string,
  handleEventChange: PropTypes.func,
  handleBlur: PropTypes.func,
}
