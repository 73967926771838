/**
 * adds a key-value pair to
 * @param key
 * @param value
 * @param expiryInSeconds number of seconds before the key-value pair expires in
 */
export const add = (key, value, expiryInSeconds) => {
  let expires = ''

  // if there is expiryInSeconds
  if (expiryInSeconds) {
    const date = new Date()
    date.setTime(date.getTime() + expiryInSeconds * 1000)
    expires = '; expires=' + date.toUTCString()
  }
  document.cookie = key + '=' + (value || '') + expires + '; path=/'
}

export default add
