const validateCreateProgram = ({ image, linkToApply, description, title, isUpdate }) => {
  const errors = {}

  if (!image && !isUpdate) {
    errors.image = 'Please upload an image.'
  }

  if (!linkToApply?.trim()) {
    errors.linkToApply = 'Please enter a link to apply.'
  }

  // not valid http(s) url.
  if (linkToApply && !/^(http|https):\/\/[^ "]+$/.test(linkToApply)) {
    errors.linkToApply = 'Please enter a valid link.'
  }

  if (!description?.trim()) {
    errors.description = 'Please enter a description.'
  }

  if (!title?.trim()) {
    errors.title = 'Please enter a title.'
  }

  const hasErrors = errors.image || errors.linkToApply || errors.description || errors.title

  return { errors, hasErrors }
}

export default validateCreateProgram
