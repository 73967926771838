import { CloseIcon, DeleteIcon } from 'assets'
import { useDisclosure, useHandleApiResponse } from 'hooks'

import Modal from './modal'
import PropTypes from 'prop-types'
import { alert } from 'utilities'
import { useDeleteMediaMutation } from 'services/media.service'

const GalleryMedia = ({ title, media_link, id, isAdmin }) => {
  const { isOpen: isMediaOpened, open: openMedia, close: closeMedia } = useDisclosure()

  const [deleteMedia, { isLoading: isDeletingMedia, ...response }] = useDeleteMediaMutation()

  const handleDelete = () => {
    deleteMedia(id)
  }

  const handleDeleteSuccess = () => {
    alert.success('Media deleted successfully.')
  }

  useHandleApiResponse({
    handleSuccess: handleDeleteSuccess,
    ...response,
  })

  const labelAndActionNode = (
    <div className={`flex ${isAdmin ? 'justify-between' : 'justify-start'} mt-3`}>
      <p className='text-sm text-[#333] leading-[140%]'>{title}</p>

      {isAdmin && (
        <button disabled={isDeletingMedia} onClick={handleDelete}>
          <DeleteIcon />
        </button>
      )}
    </div>
  )

  return (
    <>
      <div className='w-full'>
        <img
          src={media_link}
          alt={title}
          className='rounded-[5px] h-[200px] xs:h-[280px] object-cover w-full cursor-pointer border border-solid border-[#eee]'
          onClick={openMedia}
        />

        {labelAndActionNode}
      </div>
      <Modal isOpen={isMediaOpened} dialogClassName={'!w-[1300px] !max-w-full h-[800px] max-h-[100vh] '} close={closeMedia}>
        <div className='w-full h-full flex flex-col justify-between'>
          <div className='flex justify-end mb-3'>
            <button onClick={closeMedia}>
              <CloseIcon className='h-5 w-5' />
            </button>
          </div>
          <img src={media_link} alt={title} className='rounded-[5px] h-[calc(100%_-_50px)] border border-solid border-[#eee] w-full object-contain' />

          {labelAndActionNode}
        </div>
      </Modal>
    </>
  )
}

export default GalleryMedia

GalleryMedia.propTypes = {
  title: PropTypes.string,
  media_link: PropTypes.string,
  id: PropTypes.number,
  isAdmin: PropTypes.bool,
}
